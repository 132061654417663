<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="
      (value) => $store.commit('coreUi/set', ['sidebarShow', value])
    "
  >
    <CSidebarBrand class="d-md-down-none text-decoration-none" to="/dashboard">
      <CIcon
        class="c-sidebar-brand-full ml-4 pl-4 mb-2"
        name="novoLogo"
        size="custom-size"
        viewBox="0 0 380 120"
      />
      <CIcon
        class="c-sidebar-brand-minimized"
        name="sorriso"
        size="custom-size"
        :height="15"
      />
    </CSidebarBrand>
    <CRenderFunction
      flat
      :content-to-render="
        user.admin_superuser ? $options.navAdmin : $options.navUser
      "
    />
  </CSidebar>
</template>

<script>
import navAdmin from "./_navAdmin";
import navUser from "./_navUser";
import { decrypt } from "../utils/decrypt.js";

export default {
  name: "TheSidebar",
  data() {
    return {
      user: {
        admin_superuser: false
      },
    };
  },
  mounted: async function () {
    const userEncrypted = localStorage.getItem("ama");
    this.user = JSON.parse(decrypt(userEncrypted));
  },
  navAdmin,
  navUser,
  computed: {
    show() {
      return this.$store.state.coreUi.sidebarShow;
    },
    minimize() {
      return this.$store.state.coreUi.sidebarMinimize;
    },
  },
};
</script>
