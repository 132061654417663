<template>
<CDropdown inNav class="c-header-nav-items" placement="bottom-end" add-menu-classes="pt-0">
    <template #toggler>
        <CHeaderNavLink>
            <div class="c-avatar">
                <CIcon class="c-sidebar-brand-minimized" name="sorriso" size="custom-size" :height="15" />
            </div>
        </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
        <strong>Configurações</strong>
    </CDropdownHeader>
    <CDropdownItem to="/perfil">
        <CIcon name="cil-user" /> Atualizar foto
    </CDropdownItem>
    <CDropdownItem to="/senha">
        <CIcon name="cil-settings" /> Trocar senha
    </CDropdownItem>
    <CDropdownDivider />
    <CDropdownItem @click="logout">
        <CIcon name="cil-lock-locked" /> Sair
    </CDropdownItem>
</CDropdown>
</template>

<script>
import services from '../services'

export default {
    name: 'TheHeaderDropdownAccnt',
    data() {
        return {
            itemsCount: 42,
            img: null
        }
    },
    methods: {
        async logout() {
            const response = await services.auth.logout()
            if (response) {
                this.$router.push('/login')
            }
        }
    }
}
</script>

<style scoped>
.c-icon {
    margin-right: 0.3rem;
}
</style>
