<template>
<CHeader fixed with-subheader>
    <CToggler in-header class="ml-3 d-lg-none" @click="$store.commit('coreUi/toggleSidebarMobile')" />
    <CToggler in-header class="ml-3 d-md-down-none" @click="$store.commit('coreUi/toggleSidebarDesktop')" />
    <CHeaderBrand class="mx-auto d-lg-none" to="/dashboard">
        <CIcon class="c-sidebar-brand-full ml-4 pl-4 mb-2" name="novoLogo" size="custom-size" :height="35" viewBox="0 0 400 110" />
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
        <CHeaderNavItem class="px-3">
        </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderNav class="mr-2">
        <TheHeaderDropdownAccnt />
    </CHeaderNav>
</CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'

export default {
    name: 'TheHeader',
    components: {
        TheHeaderDropdownAccnt
    },
    methods: {}

}
</script>
