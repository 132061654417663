export default [
  {
    _name: "CSidebarNav",
    _children: [
      {
        _name: "CSidebarNavItem",
        name: "Início",
        to: "/dashboard",
        icon: "cil-home",
      },
      {
        _name: "CSidebarNavItem",
        name: "Usuários",
        to: "/usuarios",
        icon: "cil-people",
      },
      {
        _name: "CSidebarNavItem",
        name: "Posts",
        to: "/posts",
        icon: "cil-image",
      },
      /* {
        _name: "CSidebarNavItem",
        name: "Denuncias",
        to: "/denuncias",
        icon: "cil-warning",
      }, */
    ],
  },
];
