export default [
  {
    _name: "CSidebarNav",
    _children: [
      {
        _name: "CSidebarNavItem",
        name: "Início",
        to: "/dashboard",
        icon: "cil-home",
      },
      {
        _name: "CSidebarNavItem",
        name: "Posts",
        to: "/posts",
        icon: "cil-image",
      },
    ],
  },
];
